import { useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, Card, CardContent, CardHeader, Divider, FormHelperText, Link, Stack, TextField, Typography } from '@mui/material';
import { RouterLink } from 'components/router-link';
import { Seo } from 'components/seo';
import type { AuthContextType } from 'contexts/firebase-context';
import { useAuth } from 'hooks/use-auth';
import { useMounted } from 'hooks/use-mounted';
import { usePageView } from 'hooks/use-page-view';
import { useSearchParams } from 'hooks/use-search-params';
import { paths } from 'paths';
import type { Page as PageType } from 'types/page';
import { toast } from 'react-hot-toast';

interface Values {
    email: string;
    password: string;
    submit: null;
}

const initialValues: Values = {
    email: '',
    password: '',
    submit: null
};

const validationSchema = Yup.object({
    email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    password: Yup
        .string()
        .max(255)
        .required('Password is required')
});

const Page: PageType = () => {
    const isMounted = useMounted();
    const searchParams = useSearchParams();
    const returnTo = searchParams.get('returnTo');
    const { signInWithEmailAndPassword, signInWithGoogle } = useAuth<AuthContextType>();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                toast.loading("Signing in...");
                await signInWithEmailAndPassword(values.email, values.password);

                if (isMounted()) {
                    toast.success("Signed in.");
                    // returnTo could be an absolute path
                    // window.location.href = (returnTo || paths.index) + '?signedIn=true';
                    window.location.href = paths.index + '?signedIn=true';
                }
            } catch (err: any) {
                if (isMounted()) {
                    let errorMessage = `An error occurred, please try again (${err.code}).`;

                    switch (err.code) {
                        case "auth/user-not-found":
                        case "auth/wrong-password":
                        case "auth/invalid-email":
                        case "auth/user-disabled":
                            errorMessage = "Invalid email or password.";
                            break;
                        case "auth/too-many-requests":
                            errorMessage = "Too many requests, please try again later.";
                            break;
                        case "auth/network-request-failed":
                            errorMessage = "Network error, please try again later.";
                            break;
                        case "peerdweb/email-not-verified":
                            errorMessage = "Email not verified.  We've sent another verification email, please check your email.";
                            break;
                        default:
                            errorMessage = `An error occurred, please try again (${err.code}).`;
                            break;
                    }

                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: errorMessage });
                    helpers.setSubmitting(false);
                    toast.dismiss();
                    toast.error(errorMessage);
                }
            }
        }
    });

    const handleGoogleClick = useCallback(
        async (): Promise<void> => {
            try {
                toast.loading("Signing in...");
                await signInWithGoogle();

                if (isMounted()) {
                    toast.success("Signed in.");
                    // returnTo could be an absolute path
                    // window.location.href = (returnTo || paths.index) + '?signedIn=true';
                    window.location.href = paths.index + '?signedIn=true';
                }
            } catch (err) {
                console.error(err);
            }
        },
        [signInWithGoogle, isMounted, returnTo]
    );

    usePageView();

    return (
        <>
            <Seo title="Login" />
            <div>
                <Card elevation={16}>

                    <CardHeader
                        // subheader={(
                        //     <Typography color="text.secondary" variant="body2">
                        //         <Link component={RouterLink} href={paths.auth.register} underline="hover" variant="subtitle2" sx={{ fontSize: 16 }}>
                        //             Click here to Register
                        //         </Link>
                        //     </Typography>
                        // )}
                        sx={{ pb: 0 }}
                        titleTypographyProps={{ variant: 'h4' }}
                        title="Sign in"
                    />

                    <CardContent sx={{ pt: 2 }}>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <Box sx={{ flexGrow: 1, mt: 3 }}>
                                <Button
                                    fullWidth
                                    onClick={handleGoogleClick}
                                    size="large"
                                    sx={{
                                        backgroundColor: 'common.white',
                                        color: 'common.black',
                                        '&:hover': {
                                            backgroundColor: 'common.white',
                                            color: 'common.black'
                                        }
                                    }}
                                    variant="contained">
                                    <Box alt="Google" component="img" src="/assets/logos/logo-google.svg" sx={{ mr: 1 }} />
                                    Sign in with Google
                                </Button>

                                <Box sx={{ alignItems: 'center', display: 'flex', my: 2 }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Divider orientation="horizontal" />
                                    </Box>
                                    <Typography color="text.secondary" sx={{ m: 2 }} variant="body1">
                                        OR
                                    </Typography>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Divider orientation="horizontal" />
                                    </Box>
                                </Box>
                            </Box>

                            <Stack spacing={3}>
                                <TextField
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email && formik.errors.email}
                                    label="Email Address"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="email"
                                    value={formik.values.email}
                                />
                                <TextField
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    fullWidth
                                    helperText={formik.touched.password && formik.errors.password}
                                    label="Password"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="password"
                                    value={formik.values.password}
                                />
                            </Stack>

                            {formik.errors.submit && (
                                <FormHelperText error sx={{ mt: 3 }}>
                                    {formik.errors.submit as string}
                                </FormHelperText>
                            )}

                            <Box sx={{ mt: 2 }}>
                                <Button disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                    Sign In
                                </Button>
                            </Box>

                            <Box sx={{ flexGrow: 1, mt: 5 }}>
                                <Divider orientation="horizontal" />
                                <Typography color="text.secondary" variant="body2" sx={{ fontSize: 16 }}>
                                    <br />Don&apos;t have an account?
                                    &nbsp;
                                    <Link component={RouterLink} href={paths.auth.register} underline="hover" variant="subtitle2" sx={{ fontSize: 16, fontWeight: 'bold' }}>
                                        Click here to Register
                                    </Link>
                                </Typography>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default Page;
